.Icons p {
  user-select: none;
  color: white;

  font-size: 1.25rem;
}

.Icons > div {
  cursor: pointer;
}

.Icons > div img {
  width: 48px;
  height: 48px;

  user-select: none;
}

.Icons > div:hover p {
  background-color: #0403a4;
  color: white;
}

.Icons > div:active {
  background-color: #0403a4;
  color: white;
}

.notepadIcon {
  position: fixed;
  text-align: center;

  left: 10px;
  top: 10px;
}

.imageIcon {
  position: fixed;
  text-align: center;

  left: 17px;
  top: 120px;
}

.projectsBinIcon {
  position: fixed;
  text-align: center;
  right: 17px;
  bottom: 75px;
}

@media all and (min-width: 661px) and (max-width: 1600px) {
  .Icons > div img {
    width: 32px;
    height: 32px;
  }

  .Icons p {
    font-size: 0.75rem;
  }

  .imageIcon {
    position: fixed;
    text-align: center;

    left: 14px;
    top: 80px;
  }

  .projectsBinIcon {
    position: fixed;
    text-align: center;
    right: 17px;
    bottom: 47px;
  }
}

@media all and (min-width: 0px) and (max-width: 660px) {
  .Icons {
    display: none;
  }
}

/* АЛЬБОМНАЯ ОРИЕНТАЦИЯ */

/* Моб 279-374 */
@media (orientation: landscape) and (min-height: 279px) and (max-height: 374px) {
  .Icons {
    display: flex;
  }

  .Icons > div img {
    width: 4vw;
    height: 4vw;
  }

  .Icons p {
    font-size: 0.75rem;
  }

  .notepadIcon {
    position: fixed;
    text-align: center;
  
    left: 1vw;
    top: 4vh;
  }
  
  .imageIcon {
    position: fixed;
    text-align: center;

    left: 2vw;
    top: 24vh;
  }

  .projectsBinIcon {
    position: fixed;
    text-align: center;
    right: 2vw;
    bottom: 14vh;
  }
}

/* Моб 374-424 */
@media (orientation: landscape) and (min-height: 375px) and (max-height: 424px) {
  .Icons {
    display: flex;
  }

  .Icons > div img {
    width: 4vw;
    height: 4vw;
  }

  .Icons p {
    font-size: 0.75rem;
  }

  .notepadIcon {
    position: fixed;
    text-align: center;
  
    left: 1vw;
    top: 4vh;
  }
  
  .imageIcon {
    position: fixed;
    text-align: center;

    left: 1.75vw;
    top: 18vh;
  }

  .projectsBinIcon {
    position: fixed;
    text-align: center;
    right: 2.5vw;
    bottom: 13vh;
  }
}