.container {
    position: fixed;
    z-index: 99;
}

.LoadingScreen {
    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
    background-image: url(../../img/clouds.png);
    background-size: cover;

    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
}

.GreetingScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 95%;

    /* background-color: white; */
}

.windowsLogo {
    width: 450px;
    height: 375px;

    background-image: url(../../img/win95Logo.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

p.up {
    color: white;
    font-size: 4rem;
    margin-top: 0;
    padding: 0;
    letter-spacing: -0.2rem;

    line-height: 0.8;

    user-select: none;
}

strong.down {
    font-size: 8rem;
    margin: 0;
    padding: 0;

    color: black;
    
    user-select: none;
}

.BottomLoader {
    width: 100%;
    height: 5%;

    background: linear-gradient(90deg, #738dd6, #a8ddff, #739bd6, #738dd6, #a8ddff, #739bd6, #738dd6, #a8ddff, #739bd6, #738dd6, #a8ddff, #739bd6);
    background-size: 300%;

    animation: gradient 8s ease-out infinite;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@media all and (min-width: 661px) and (max-width: 1600px) {
    .windowsLogo {
        width: 300px;
        height: 250px;
    }

    p.up {
        font-size: 2.67rem;
    }

    strong.down {
        font-size: 5.33rem;
    }
}

/* Моб 320-660 */
@media all and (min-width: 320px) and (max-width: 660px) {
    .windowsLogo {
        width: 273px;
        height: 227px;
    }

    p.up {
        font-size: 2.3rem;
    }

    strong.down {
        font-size: 4.4rem;
    }
  }

  
/* АЛЬБОМНАЯ ОРИЕНТАЦИЯ */

/* Мобильные устройства от 279 до 374 пикселей */
@media (orientation: landscape) and (min-height: 279px) and (max-height: 374px) {
 .GreetingScreen {
    height: 90%;
 }   

 .BottomLoader {
    height: 10%;
 }

 .windowsLogo {
    width: 187px;
    height: 155px;
}

p.up {
    font-size: 2rem;
}

strong.down {
    font-size: 4rem;
}
}


/* Мобильные устройства от 375 до 600 пикселей */
@media (orientation: landscape) and (min-height: 375px) and (max-height: 600px) {
    .GreetingScreen {
       height: 92%;
    }   
   
    .BottomLoader {
       height: 8%;
    }
   
    .windowsLogo {
       width: 215px;
       height: 180px;
   }
   
   p.up {
       font-size: 2rem;
   }
   
   strong.down {
       font-size: 4rem;
   }
   }