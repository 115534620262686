.startPanel {
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 100vw;
}

.Header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 50px;
  background: linear-gradient(90deg, #cdcbce 0%, #c0c0c2 100%);
  border-top: 2px solid #e2e2e2;
  padding: 0 0.75rem 0 0.25rem;
}

.panelToolbox {
  display: flex;
  height: 99%;
  align-items: center;
}

.panelToolbox > img {
  width: 101px;
  height: 41px;

  border: 2px solid transparent;
  user-select: none;
  outline: none;

  border-right: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 2px solid white;
  border-top: 2px solid white;

  transition: border 0.2s ease-out;

  margin-right: 1rem;
}

.panelToolbox > img:hover {
  cursor: pointer;
}

.panelToolbox > img:active {
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid black;
  border-top: 2px solid black;
  cursor: pointer;

  transition: border 0.05s ease-in;
}

img.nowActive,
div.nowActive {
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid black;
  border-top: 2px solid black;
}

div.nowActive {
  background-image: -webkit-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 100%
  );
  -webkit-background-size: 3px 3px;
  -moz-background-size: 3px 3px;
  background-size: 3px 3px;
  background-color: #e7e7e7;
}

.applicationTray {
  display: flex;
  align-items: center;

  font-weight: bold;

  height: 84%;
  width: 200px;
  background-color: #c0c0c2;

  border-bottom: 2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid white;
  border-top: 2px solid white;

  cursor: pointer;

  margin-right: 0.5rem;

  transition: border 0.2s ease-out;
}

.applicationTray:active {
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid black;
  border-top: 2px solid black;

  background-image: -webkit-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -moz-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: -ms-repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 100%
  );
  background-image: repeating-radial-gradient(
    center center,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px,
    transparent 100%
  );
  -webkit-background-size: 3px 3px;
  -moz-background-size: 3px 3px;
  background-size: 3px 3px;
  background-color: #e7e7e7;

  transition: border 0.05s ease-in;
}

.applicationTray > img {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.25rem 0 0.25rem;

  user-select: none;
}

.applicationTray > p {
  user-select: none;
}

.timeBox {
  margin-top: 0.2rem;

  font-weight: bold;

  width: 100px;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c0c0c2;

  border-bottom: 2px solid white;
  border-left: 2px solid #6c6b6d;
  border-top: 2px solid #6c6b6d;
}

/* Ноутбуки и планшеты */
@media all and (min-width: 661px) and (max-width: 1600px) {
  .Header {
    height: 30px;
    padding-right: 0.5rem;
  }

  .panelToolbox > img {
      height: 25px;
      width: 64px;
  }

  .timeBox {
    width: 75px;
    font-size: 0.75rem;
    margin-top: 0.1rem;
  }

  .applicationTray {
    width: 150px;
    font-size: 0.75rem;
  }

  .applicationTray > img {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.25rem 0 0.25rem;
  
    user-select: none;
  }
}

/* Мобильные устройства от 279 до 374 пикселей */
@media all and (min-width: 279px) and (max-width: 374px) {
  .Header {
    height: 40px;

    margin: 0;
    padding: 0 0 0 0.25rem;
  }

  .panelToolbox {
    width: 100%;
    justify-content: space-between;
  }

  .panelToolbox > img {
      height: 33px;
      width: 89px;
  }

  .timeBox {
    margin-top: 0.1rem;
    margin-right: 0.25rem;
    margin-left: 0.75rem;
    
    width: 75px;
    height: 2rem;

    font-size: 1rem;
  }

  .applicationTray {
    width: 2.5rem;
    font-size: 0.75rem;
  }

  .applicationTray > img {
    width: 2rem;
    height: 2rem;
    margin: 0 0.25rem 0 0.25rem;
  
    user-select: none;
  }

  .applicationTray > p {
    display: none;
  }
}

/* Мобильные устройства от 375 до 424 пикселей */
@media all and (min-width: 375px) and (max-width: 424px) {
  .Header {
    height: 40px;
    padding-right: 0.5rem;
  }

  .panelToolbox > img {
      height: 33px;
      width: 89px;
  }

  .timeBox {
    width: 75px;
    height: 30px;

    font-size: 1rem;
  }

  .applicationTray {
    width: 2.5rem;
    font-size: 0.75rem;
  }

  .applicationTray > img {
    width: 2rem;
    height: 2rem;
    margin: 0 0.25rem 0 0.25rem;
  
    user-select: none;
  }

  .applicationTray > p {
    display: none;
  }
}

/* Мобильные устройства от 425 до 660 пикселей */
@media all and (min-width: 425px) and (max-width: 660px) {
  .Header {
    height: 50px;
    padding-right: 0.5rem;
  }

  .panelToolbox > img {
      height: 41px;
      width: 101px;
  }

  .timeBox {
    width: 75px;
    height: 41px;
    font-size: 0.75rem;
    margin-top: 0.1rem;

    font-size: 1rem;
  }

  .applicationTray {
    width: 2.5rem;
    font-size: 0.75rem;
  }

  .applicationTray > img {
    width: 2rem;
    height: 2rem;
    margin: 0 0.25rem 0 0.25rem;
  
    user-select: none;
  }

  .applicationTray > p {
    display: none;
  }
}

/* АЛЬБОМНАЯ ОРИЕНТАЦИЯ */


/* Мобильные устройства от 279 до 374 пикселей */
@media (orientation: landscape) and (min-height: 279px) and (max-height: 374px) {
  .Header {
    height: 30px;

    margin: 0;
    padding: 0 0 0 0.25rem;
  }

  .panelToolbox > img {
      height: 24px;
      width: 68px;
  }

  .timeBox {
    margin-top: 0.1rem;
    margin-right: 0.25rem;
    margin-left: 0.75rem;
    
    width: 11vw;
    height: 7vh;

    font-size: 2vw;
  }

  .applicationTray {
    width: 21vw;
    height: 22px;
    font-size: 0.65rem;
  }

  .applicationTray > img {
    width: 3vw;
    height: 3vw;
    margin: 0 0.25rem 0 0.25rem;
  
    user-select: none;
  }

  .applicationTray > p {
    display: flex;
  }
}

/* Мобильные устройства от 375 до 424 пикселей */
@media (orientation: landscape) and (min-height: 375px) and (max-height: 424px) {
  .Header {
    height: 10vh;

    margin: 0;
    padding: 0 0 0 0.25rem;
  }

  .panelToolbox > img {
      height: 8vh;
      width: 12vw;
  }

  .timeBox {
    margin-top: 0.1rem;
    margin-right: 0.25rem;
    margin-left: 0.75rem;
    
    width: 11vw;
    height: 8vh;

    font-size: 2.15vw;
  }

  .applicationTray {
    width: 21vw;
    font-size: 0.75rem;
  }

  .applicationTray > img {
    width: 24px;
    height: 24px;
    margin: 0 0.25rem 0 0.25rem;
  
    user-select: none;
  }

  .applicationTray > p {
    display: flex;
  }
}

/* Мобильные устройства от 425 до 660 пикселей */
@media (orientation: landscape) and (min-height: 425px) and (max-height: 600px) {
  .Header {
    height: 50px;
    padding-right: 0.5rem;
  }

  .panelToolbox > img {
      height: 41px;
      width: 101px;
  }

  .timeBox {
    width: 75px;
    height: 41px;
    font-size: 0.75rem;
    margin-top: 0.1rem;

    font-size: 1rem;
  }

  .applicationTray {
    width: 2.5rem;
    font-size: 0.75rem;
  }

  .applicationTray > img {
    width: 2rem;
    height: 2rem;
    margin: 0 0.25rem 0 0.25rem;
  
    user-select: none;
  }

  .applicationTray > p {
    display: none;
  }
}