.Picture {
    display: flex;
    justify-content: center;
}

.notepad {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0.25rem;

    width: 300px;
    height: 300px;
    background-color: #CBC9CC;

    border-bottom: 2px solid black;
    border-right: 2px solid black;
    border-left: 2px solid white;
    border-top: 2px solid white;
}

.topPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    height: 10.5%;

    background: linear-gradient(90deg, #0403a4 0%, #00007e 100%);

    color: white;
}

.topPanelLeft, .topPanelRight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topPanelLeft {
    user-select: none;
}

.topPanelLeft img {
    width: 20px;
    margin: 0 0.2rem 0 0.2rem;
}

.topPanelRight div {
    height: 15px;
    width: 15px;

    background-color: #CBC9CC;
    color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bolder;

    user-select: none;
    cursor: pointer;

    border: 2px solid #CBC9CC;

    margin-right: 0.25rem;
    
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
}

.topPanelRight div:active {
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
}

.bottomPanel {
    display: flex; 
    flex-direction: row;

    width: 99.55%;
    height: 87.45%;

    background-color: rgb(241, 241, 241);

    border-top: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
}

.mainPanel {
    width: 100%;
    height: 100%;

    background-image: url(../../img/me2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

/* Ноутбуки и планшеты */
@media all and (min-width: 661px) and (max-width: 1500px) {
    .notepad {
        width: 250px;
        height: 250px;
      }

    .topPanel {
        height: 9%;
        font-size: 0.75rem;
      }
    
      .linksPanel {
        font-size: 0.75rem;
      }
      
      .topPanelLeft img {
        width: 15px;
      }
    
      .topPanelRight div {
        height: 12px;
        width: 12px;
    
        font-size: 0.75rem;
      }
}

/* Мобильные устройства от 0 до 319 пикселей */
@media all and (min-width: 0px) and (max-width: 319px) {
    .notepad {
      width: 75vw;
      height: 85vw;
    }
  
    .mainPanel textarea {
        font-size: 2.725vh;
    }
  
    .topPanel {
      font-size: 0.75rem;
    }
    
    .linksPanel {
      font-size: 0.75rem;
    }
  }

/* Мобильные устройства от 320 до 374 пикселей */
@media all and (min-width: 320px) and (max-width: 374px) {
    .notepad {
        width: 270px;
        height: 270px;
      }

    .topPanel {
        height: 10%;
      } 
}

/* Мобильные устройства от 375 до 660 пикселей */
@media all and (min-width: 375px) and (max-width: 660px) {
    .notepad {
        width: 75vw;
      height: 85vw;
      }

    .topPanel {
        height: 10%;
      } 
}

/* АЛЬБОМНАЯ ОРИЕНТАЦИЯ */

/* 279-374 */
@media (orientation: landscape) and (min-height: 279px) and (max-height: 374px) {
  .notepad {
    width: 45vh;
  height: 45vh;
  }
  
  .topPanel {
    height: 8%;
  } 
  
  .topPanel {
    font-size: 0.55rem;
  }

  .topPanelLeft img{
    width: 3vh;
    height: 3vh;
  }

  .topPanelLeft p {
    display: none;
  }

  .topPanelRight div {
    width: 1.5vh;
    height: 1.5vh;

    font-size: 0.45rem;
  }
}

/* 375-600 */
@media (orientation: landscape) and (min-height: 375px) and (max-height: 600px) {
  .notepad {
    width: 50vh;
    height: 50vh;
  }
  
  .topPanel {
    height: 9%;
  } 
  
  .topPanel {
    font-size: 0.65rem;
  }

  .topPanelLeft img{
    width: 4vh;
    height: 4vh;
  }

  .topPanelRight div {
    width: 2vh;
    height: 2vh;

    font-size: 0.45rem;
  }
}