.Voxel {
    width: 100vw;
    height: 30vh;
}

@media all and (min-width: 320px) and (max-width: 660px) {
    .Voxel {
        width: 100vw;
        height: 25vh;
    }
  }