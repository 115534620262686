.ProjectsBin {
  display: flex;
  justify-content: center;
}

.notepad {
  display: flex;
  flex-direction: column;

  padding: 0.25rem;

  width: 650px;
  height: 450px;
  background-color: #cbc9cc;

  border-bottom: 2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid white;
  border-top: 2px solid white;
}

.topPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 6%;

  background: linear-gradient(90deg, #0403a4 0%, #00007e 100%);

  color: white;
}

.topPanelLeft,
.topPanelRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topPanelLeft {
  user-select: none;
}

.topPanelLeft img {
  width: 20px;
  margin: 0 0.2rem 0 0.2rem;
}

.topPanelRight div {
  height: 15px;
  width: 15px;

  background-color: #cbc9cc;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bolder;

  user-select: none;
  cursor: pointer;

  border: 2px solid #cbc9cc;

  margin-right: 0.25rem;

  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.topPanelRight div:active {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.linksPanel {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 6%;

  margin-left: 0.25rem;

  align-items: center;
}

.linksPanel p {
  user-select: none;
  cursor: pointer;
  margin-right: 1rem;
}

.linksPanel p:hover {
  background-color: #0403a4;
  color: white;
}

p.clearAll:active {
  background-color: white;
  color: #0403a4;
}

.bottomPanel {
  display: flex;
  flex-direction: row;

  width: 99.2%;
  height: 81.45%;

  background-color: rgb(241, 241, 241);

  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.mainPanel {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  align-items: center;
}

.ProjectLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: black;
}

.ProjectLink:hover p {
  color: white;
  background-color: #00007e;
}

.ProjectLink:active p {
  color: white;
  background-color: #00007e;
}

.ProjectLink img {
  width: 64px;
  height: 64px;
}

.downPanel {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  width: 99.85%;
  height: 6%;
}

.downPanel p {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.leftDownPanel {
  width: 69.5%;
  height: 65%;

  margin-right: 0.5%;
  padding-left: 0.5%;

  border-top: 2px solid #7e7e7e;
  border-left: 2px solid #7e7e7e;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.rightDownPanel {
  width: 30%;
  height: 65%;

  padding-left: 0.5%;

  border-top: 2px solid #7e7e7e;
  border-left: 2px solid #7e7e7e;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

/* Ноутбуки и планешты */
@media all and (min-width: 668px) and (max-width: 1500px) {
  .notepad {
    width: 500px;
    height: 325px;
  }

  .topPanel {
    font-size: 0.75rem;
  }

  .linksPanel {
    font-size: 0.75rem;
  }

  .topPanelLeft img {
    width: 15px;
  }

  .topPanelRight div {
    height: 12px;
    width: 12px;

    font-size: 0.75rem;
  }

  .mainPanel {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .ProjectLink img {
    width: 32px;
    height: 32px;
  }

  .downPanel p {
    font-size: 0.75rem;
  }
}

/* Мобильные устройства от 320 до 374 пикселей */
@media all and (min-width: 0px) and (max-width: 319px) {
  .notepad {
    width: 80vw;
    height: 60vh;
  }

  .ProjectLink img {
    width: 32px;
    height: 32px;
  }

  .mainPanel {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Мобильные устройства от 320 до 374 пикселей */
@media all and (min-width: 320px) and (max-width: 374px) {
  .notepad {
    width: 80vw;
    height: 64vh;
  }

  .ProjectLink img {
    width: 32px;
    height: 32px;
  }

  .Project:not(:nth-child(5n)) {
    margin-right: 0rem;
  }
  .Project:not(:nth-child(3n)) {
    margin-right: 3rem;
  }

  .downPanel {
    font-size: 0.75rem;
  }

  .mainPanel {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Мобильные устройства от 375 до 660 пикселей */
@media all and (min-width: 375px) and (max-width: 660px) {
  .notepad {
    width: 80vw;
    height: 64vh;
  }

  .ProjectLink img {
    width: 32px;
    height: 32px;
  }

  .downPanel {
    font-size: 0.9rem;
  }

  .mainPanel {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* АЛЬБОМНАЯ ОРИЕНТАЦИЯ */

/* 279-374 */
@media (orientation: landscape) and (min-height: 279px) and (max-height: 374px) {
  .notepad {
    width: 65vw;
    height: 55vh;
  }

  .topPanel {
    font-size: 3.5vh;
  }

  .topPanelLeft img {
    width: 1vw;
    height: 1vw;
  }

  .topPanelRight div {
    font-size: 3vh;

    width: 0.5vw;
    height: 0.5vw;
  }

  .linksPanel {
    font-size: 3.5vh;
  }

  .downPanel {
    font-size: 2.5vh;
  }

  .Project p {
    font-size: 0.65rem;
  }

  .ProjectLink img {
    width: 16px;
    height: 16px;
  }
}

/* 375-600 */
@media (orientation: landscape) and (min-height: 375px) and (max-height: 600px) {
  .notepad {
    width: 55vw;
    height: 55vh;
  }

  .topPanel {
    height: 9%;
    font-size: 3vh;
  }

  .topPanelLeft img {
    width: 2vw;
    height: 2vw;
  }

  .topPanelRight div {
    font-size: 3vh;

    width: 1.5vw;
    height: 1.5vw;
  }

  .linksPanel {
    height: 5%;
    font-size: 3vh;
  }

  .downPanel p {
    font-size: 2vh;
  }

  .Project p {
    font-size: 2vw;
  }

  .ProjectLink img {
    width: 24px;
    height: 24px;
  }
}
