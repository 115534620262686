.Bio {
  display: flex;
  justify-content: center;
}

.notepad {
  display: flex;
  flex-direction: column;

  padding: 0.25rem;

  width: 650px;
  height: 450px;
  background-color: #cbc9cc;

  border-bottom: 2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid white;
  border-top: 2px solid white;
}

.topPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 6%;

  background: linear-gradient(90deg, #0403a4 0%, #00007e 100%);

  color: white;
}

.topPanelLeft,
.topPanelRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topPanelLeft {
  user-select: none;
}

.topPanelLeft img {
  width: 20px;
  margin: 0 0.2rem 0 0.2rem;
}

.topPanelRight div {
  height: 15px;
  width: 15px;

  background-color: #cbc9cc;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bolder;

  user-select: none;
  cursor: pointer;

  border: 2px solid #cbc9cc;

  margin-right: 0.25rem;

  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.topPanelRight div:active {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.linksPanel {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 6%;

  margin-left: 0.25rem;

  align-items: center;
}

.linksPanel p {
  user-select: none;
  cursor: pointer;
  margin-right: 1rem;
}

.linksPanel p:hover {
  background-color: #0403a4;
  color: white;
}

.bottomPanel {
  display: flex;
  flex-direction: row;

  width: 99.55%;
  height: 87.45%;

  background-color: white;

  border-top: 2px solid black;
  border-left: 2px solid black;
}

.mainPanel {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.mainPanel textarea {
  width: 99%;
  height: 99%;

  resize: none;
  border: none;
  font-family: W95FA;
  font-weight: bold;
  font-size: 32px;

  outline: none;
}

/* Ноутбуки и планшеты */
@media all and (min-width: 661px) and (max-width: 1500px) {
  .notepad {
    width: 500px;
    height: 325px;
  }

  .topPanel {
    font-size: 0.75rem;
  }

  .linksPanel {
    font-size: 0.75rem;
  }
  
  .topPanelLeft img {
    width: 15px;
  }

  .topPanelRight div {
    height: 12px;
    width: 12px;

    font-size: 0.75rem;
  }

  .mainPanel textarea {
      font-size: 24px;
  }
}

/* Мобильные устройства от 0 до 319 пикселей */
@media all and (min-width: 0px) and (max-width: 319px) {
  .notepad {
    width: 75vw;
    height: 59vh;
  }

  .mainPanel textarea {
      font-size: 2.725vh;
  }

  .topPanel {
    font-size: 0.75rem;
  }
  
  .linksPanel {
    font-size: 0.75rem;
  }
}

/* Моб 320-374 */
@media all and (min-width: 320px) and (max-width: 374px) {
  .notepad {
    width: 75vw;
    height: 62vh;
  }

  .mainPanel textarea {
      font-size: 2.9vh;
  }
}

/* Моб 375-424 */
@media all and (min-width: 375px) and (max-width: 660px) {
  .notepad {
    width: 80vw;
    height: 63vh;
  }

  .mainPanel textarea {
      font-size: 3.3vh;
  }
}

/* АЛЬБОМНАЯ ОРИЕНТАЦИЯ */

/* Мобильные устройства от 279 до 374 пикселей */
@media (orientation: landscape) and (min-height: 279px) and (max-height: 374px) {
  .notepad {
    width: 65vw;
    height: 55vh;
  }

  .mainPanel textarea {
      font-size: 4.46vh;
  }

  .topPanel {
    font-size: 3.5vh;
  }

  .topPanelLeft img {
    width: 1vw;
    height: 1vw;
  }

  .topPanelRight div {
    font-size: 3vh;

    width: 0.5vw;
    height: 0.5vw;
  }

  .linksPanel {
    font-size: 3.5vh;
  }
}

/* Мобильные устройства от 375 до 600 пикселей */
@media (orientation: landscape) and (min-height: 375px) and (max-height: 600px) {
  .notepad {
    width: 65vw;
    height: 55vh;
  }

  .mainPanel textarea {
      font-size: 4.46vh;
  }

  .topPanel {
    font-size: 3.5vh;
  }

  .topPanelLeft img {
    width: 1.5vw;
    height: 1.5vw;
  }

  .topPanelRight div {
    font-size: 3vh;

    width: 1vw;
    height: 1vw;
  }

  .linksPanel {
    font-size: 3vh;
  }
}