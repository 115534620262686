.Menu {
    position: fixed;
    width: 325px;
    height: 430px;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #c5c5c5 0%, #c3c3c3 100%);

    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-left: 2px solid white;
    border-top: 2px solid white;

    z-index: 10;
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.win95plank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14%;
    height: 100%;
    
    background: linear-gradient(90deg, #7a7c7a 0%, #757775 100%);
}

.win95plank p {
    width: 150px;
    transform: rotate(270deg);
    color: #bdbebd;
    font-weight: bolder;
    font-size: 32px;

    user-select: none;
}

.linksMenu {
    width: 86%;
    height: 100%;
}

.linkContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0.5rem;
    align-items: center;
}

.linkContainer img {
    width: 45px;
    height: 45px;
}

.linkContainerText {
    width: 65%;
}

.linkContainer {
    color: black;
    transition: all 0.1s ease-out;
}

.linkContainer:hover {
    color: white;
    background-color: #0f0793;
    transition: all 0.1s ease-in;
}

@media all and (min-width:661px) and (max-width: 1600px) {
    .Menu {
        width: 238px;
        height: 324px;
    }

    .linkContainer img {
        width: 30px;
        height: 30px;
    }
}

/* Мобильные устройства от 0 до 319 пикселей */

@media all and (min-width: 0px) and (max-width: 319px) {
    .Menu {
        width: 73.25vw;
        height: 334px;
    }

    .linkContainer img {
        width: 30px;
        height: 30px;
    }
}

/* Мобильные устройства от 320 до 374 пикселей */
@media all and (min-width: 320px) and (max-width: 374px) {
    .Menu {
        width: 59.35vw;
        height: 344px;
    }

    .linkContainer img {
        width: 30px;
        height: 30px;
    }
}

/* Мобильные устройства от 375 до 424 пикселей */
@media all and (min-width: 375px) and (max-width: 424px) {
    .Menu {
        width: 217px;
        height: 344px;
    }

    .linkContainer img {
        width: 30px;
        height: 30px;
    }
}

/* АЛЬБОМНАЯ ОРИЕНТАЦИЯ */

/* Мобильные устройства от 279 до 374 пикселей */
@media (orientation: landscape) and (min-height: 279px) and (max-height: 374px) {
    .Menu {
        width: 34.6vw;
        height: 290px;
    }

    .linkContainer img {
        width: 24px;
        height: 24px;
    }
}

/* Мобильные устройства от 375 до 600 пикселей */
@media (orientation: landscape) and (min-height: 375px) and (max-height: 600px) {
    .Menu {
        width: 34.6vw;
        height: 345px;
    }

    .linkContainer img {
        width: 32px;
        height: 32px;
    }
}